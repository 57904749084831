import React from 'react'
import Draggable from 'react-draggable'
import {Editor, EditorState, ContentState} from 'draft-js'
import $ from 'jquery'

class ElementHotspotsPOI extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      drag: false,
      position: this.props.position,
      textContent: EditorState.createWithContent(ContentState.createFromText(this.props.text)),
      text: this.props.text,
      focus: false
    }

    this.reset = false
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(!this.state.drag) {
      this.resetPosition(props)
    }

    if(!this.state.focus) {
      if(props.text !== this.state.text) {
        const selectionState = this.state.textContent.getSelection()
        const newContentState = ContentState.createFromText(props.text)
        const newEditorState = EditorState.create({
          currentContent: newContentState,
          selection: selectionState
        })
        this.setState({
          textContent: newEditorState,
          text: props.text
        })
      }
    }
  }

  resetPosition(props) {
    var thisPOI = this
    thisPOI.setState(
      {
        position: props.position
      },
      function() {
        thisPOI.reset = true
      }
    )
  }

  handleDrag(e, element) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      drag: true
    })
    var x = $(element.node).offset().left - $(element.node).closest('.pois').offset().left
    var y = $(element.node).offset().top - $(element.node).closest('.pois').offset().top
    var xPercent = x * 100 / $(element.node).closest('.pois').outerWidth()
    var yPercent = y * 100 / $(element.node).closest('.pois').outerHeight()
    this.props.onChangePosition(xPercent, yPercent)
  }

  handleStop(e, element) {
    e.preventDefault()
    e.stopPropagation()
    var x = $(element.node).offset().left - $(element.node).closest('.pois').offset().left
    var y = $(element.node).offset().top - $(element.node).closest('.pois').offset().top
    var xPercent = x * 100 / $(element.node).closest('.pois').outerWidth()
    var yPercent = y * 100 / $(element.node).closest('.pois').outerHeight()
    this.props.onChangePosition(xPercent, yPercent)
    this.resetPosition(this.props)
    var thisPOI = this
    setTimeout(function() {
      thisPOI.setState({
        drag: false
      })
    }, 1)
  }

  handleClick() {
    if(!this.state.drag) {
      this.props.onToggle()
    }
  }

  handleTextChange(textContent) {
    var text = textContent.getCurrentContent().getPlainText()
    this.setState({
      textContent: textContent,
      text: text
    })
    this.props.onChangeText(text)
  }

  handleFocus() {
    this.setState({
      focus: true
    })
  }

  handleBlur() {
    this.setState({
      focus: false
    })
  }

  render() {
    return(
      <Draggable
        bounds="parent"
        onDrag={this.handleDrag.bind(this)}
        onStop={this.handleStop.bind(this)}
        disabled={this.props.active}
        position={this.reset ? {x: 0, y: 0} : null}
      >
        <div
          className={'poi' + (this.state.position.x > 50 ? ' inverted' : '') + (this.props.active ? ' active' : '')}
          style={(this.state.position !== undefined ? {left: this.state.position.x + '%', top: this.state.position.y + '%'} : {})}
        >
          <div className="poi-button" onClick={this.handleClick.bind(this)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <g transform="translate(-9 -9)">
                <rect width="2" height="12" transform="translate(14 9)" />
                <rect width="12" height="2" transform="translate(9 14)" />
              </g>
            </svg>
          </div>
          <div className="poi-text">
            <Editor
              placeholder="Text..."
              editorState={this.state.textContent}
              onChange={this.handleTextChange.bind(this)}
              onFocus={this.handleFocus.bind(this)}
              onBlur={this.handleBlur.bind(this)}
            />
          </div>
        </div>
      </Draggable>
    )
  }
}

export default ElementHotspotsPOI
