import React from 'react'
import {withRouter} from 'react-router'

import CmsHelper from '../../../helpers/Cms'

class Window extends React.Component {
  constructor(props) {
    super(props)

    this.title = React.createRef()
    this.duration = React.createRef()

    this.state = {
      exported: false
    }
  }

  getInputValue(name) {
    return this[name].current.value
  }

  getEndpoint() {
    return 'https://fichtner.fp-eauthor.fp-server.com/cms'
  }

  async loadImageSrc(id) {
    var file
    if(id !== undefined && id !== '') {
      var response = await CmsHelper.get({
        type: 'attachments',
        id: id
      })
      file = response.file.path
    } else {
      var response = await CmsHelper.getSingleton({
        name: 'defaults'
      })
      file = response.placeholderImage.path
    }
    var image = CmsHelper.getImageUrl({
      file: file,
      width: 800,
      height: 450
    })
    return image
  }

  async exportWBT(args) {
    var imageSrc = await this.loadImageSrc(args.imageId)
    console.log(imageSrc)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json")
    myHeaders.append("Access-Control-Allow-Origin", "*")
    myHeaders.append("Access-Control-Allow-Methods", "DELETE, PUT, POST, GET, OPTIONS")
    myHeaders.append("Access-Control-Allow-Headers", "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With")
    myHeaders.append("Authorization", "Basic bWVkaWF3ZXJrOnF1dzl5Zmlx")
    console.log(myHeaders)
    var response2 = await fetch(
      'https://cors-anywhere.herokuapp.com/https://lms.aciso-academy.fp-server.com/api/wbts/' + args.id + '?image=' + encodeURIComponent(imageSrc) + '&title=' + args.title + '&duration=' + args.duration,
      {
        method: 'PUT',
        headers: myHeaders,
        redirect: 'follow'
      }
    ).then(res => res.json())
    this.setState({
      exported: true
    })
  }

  handleExportWbt(e) {
    e.preventDefault()

    this.exportWBT({
      id: this.props.wbtId,
      imageId: this.props.settings.image,
      title: this.getInputValue('title'),
      duration: this.getInputValue('duration')
    })
  }

  render() {
    var title = this.props.settings.title
    return(
      <div className="modal-window export">
        <div className="headline">Exportieren</div>
        <div className="main">
          {
            !this.state.exported ?
              <form
                className="form"
                onSubmit={this.handleExportWbt.bind(this)}
              >
                <div className="field">
                  <div className="category">WBT Titel</div>
                  <input
                    type="text"
                    ref={this.title}
                    defaultValue={title}
                    placeholder="WBT Titel"
                  />
                </div>
                <div className="field">
                  <div className="category">Dauer</div>
                  <input
                    type="text"
                    ref={this.duration}
                    placeholder="Dauer"
                  />
                </div>
                <button
                  className="button highlighted round"
                >
                  <span className="label">In LMS exportieren</span>
                </button>
              </form>
            :
              <div>Das WBT wurde erfolgreich in das LMS exportiert!</div>
          }
        </div>
        <button
          className="close-modal"
          onClick={this.props.onCloseModal}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25.223" height="25.223" viewBox="0 0 25.223 25.223">
            <path d="M0,10.165H7.671v7.671h2.4V10.165h7.765v-2.4H10.071V0h-2.4V7.765H0Z" transform="translate(12.611 0) rotate(45)" />
          </svg>
        </button>
      </div>
    )
  }
}

export default withRouter(Window)
