import React from 'react'

import ElementBase from './ElementBase'

class ElementAudio extends ElementBase {
  html() {
    var settings = this.props.element.settings
    return(
      <div className="audio">
        <button className="button audio">Lies mir den Text vor</button>
      </div>
    )
  }
}

export default ElementAudio
