import React from 'react'
import ReactDOM from 'react-dom'
import {DropTarget} from 'react-dnd'

import Element from './Element'
import EditorDropIndicator from '../../components/EditorDropIndicator'
import ElementColumnsColumnElementEmpty from './ElementColumnsColumnElementEmpty'

const columnTarget = {
  canDrop(props, monitor) {
    if(props.columnsCount >= 4 || (monitor.getItem().itemType === 'element' && props.columnElementIndex !== undefined)) {
      return false
    } else {
      return true
    }
  },
  hover(props, monitor, component) {
    var componentRect = ReactDOM.findDOMNode(component).getBoundingClientRect()
    var x = monitor.getClientOffset().x - componentRect.x
    var y = monitor.getClientOffset().y - componentRect.y
    if(x <= componentRect.width / 4 && monitor.getItem().itemType === 'column-element') {
      if(component.state.isDragOver !== 'left') {
        component.setState({
          isDragOver: 'left'
        })
      }
    } else if(x >= componentRect.width / 4 * 3 && monitor.getItem().itemType === 'column-element') {
      if(component.state.isDragOver !== 'right') {
        component.setState({
          isDragOver: 'right'
        })
      }
    } else {
      if(y < componentRect.height / 2) {
        if(component.state.isDragOver !== 'top') {
          component.setState({
            isDragOver: 'top'
          })
        }
      } else {
        if(component.state.isDragOver !== 'bottom') {
          component.setState({
            isDragOver: 'bottom'
          })
        }
      }
    }
  },
  drop(props, monitor, component) {
    if(monitor.canDrop()) {
      var item = monitor.getItem()
      var moduleIndex = props.moduleIndex
      var elementIndex = props.elementIndex
      var columnIndex = props.columnIndex
      var columnElementIndex = props.columnElementIndex
      if(component.state.isDragOver === 'bottom') {
        if(columnElementIndex !== undefined) {
          columnElementIndex += 1
        } else {
          elementIndex += 1
        }
      }
      if(item.elementId !== undefined) {
        var source
        if(props.overlayId !== undefined) {
          source = {
            overlayId: props.overlayId,
            elementId: item.elementId,
          }
        } else {
          source = {
            moduleId: item.moduleId,
            elementId: item.elementId,
          }
        }
        if(item.columnElementId !== undefined) {
          source.columnId = item.columnId
          source.columnElementId = item.columnElementId
        }
        var target = {
          moduleIndex: moduleIndex,
          elementIndex: elementIndex
        }
        if(component.state.isDragOver === 'left' || component.state.isDragOver === 'right') {
          target.newColumn = component.state.isDragOver
        }
        target.columnIndex = columnIndex
        props.onMoveInside(source, target)
      } else {
        var args
        if(props.overlayId !== undefined) {
          args = {
            overlayId: props.overlayId,
            overlayIndex: 0,
            elementIndex: elementIndex,
            columnIndex: props.columnIndex,
            elementType: monitor.getItem().type
          }
        } else {
          args = {
            moduleIndex: moduleIndex,
            elementIndex: elementIndex,
            columnIndex: props.columnIndex,
            elementType: monitor.getItem().type
          }
        }
        if(component.state.isDragOver === 'left' || component.state.isDragOver === 'right') {
          args.newColumn = component.state.isDragOver
        }
        if(columnElementIndex !== undefined) {
          args.columnIndex = columnIndex
          args.columnElementIndex = columnElementIndex
        }
        props.onAddInside(args)
      }
    }
  }
}

function columnCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver({
      shallow: false
    })
  }
}

class ElementColumnsColumn extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      isDragOver: 'test'
    }
  }

  render() {
    var props = {}
    var isDragOver = (this.props.isOver && this.props.canDrop) ? this.state.isDragOver : false
    var elements = []
    if(this.props.column.settings.elements !== undefined) {
      elements = this.props.column.settings.elements.map((element, index) =>
        <Element
          key={element.id}
          {...this.props}
          columnElementIndex={index}
          columnElementId={element.id}
          element={element}
        />
      )
    }
    return this.props.connectDropTarget(
      <div
        className={'column' + (this.props.canDrop ? ' show-borders' : '') + (isDragOver === 'left' || isDragOver === 'right' ? ' new-column' : '')}
      >
        <EditorDropIndicator
          position="left"
          show={isDragOver === 'left' ? true : false}
        />
        <EditorDropIndicator
          position="right"
          show={isDragOver === 'right' ? true : false}
        />
        {
          elements.length > 0 ?
            elements
          :
            <ElementColumnsColumnElementEmpty
              {...this.props}
            />
        }
      </div>
    )
  }
}

export default DropTarget(
  'column-element',
  columnTarget,
  columnCollect
)(ElementColumnsColumn)
