import React from 'react'
import { Form } from 'semantic-ui-react';
import {withRouter} from 'react-router'
import axios from 'axios';

import InputImage from './WindowSettingsInputImage'

import CommonHelper from '../../../helpers/Common'
import CmsHelper from '../../../helpers/Cms'

class Window extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tab: 'settings',
      title: '',
      revisions: [],
      wbtCategories: this.props.settings.categories,
      categories: [],
    }
  }

  componentDidMount() {
    this.loadTitle()
    this.loadRevisions()
    this.getCategories()
  }

  async getCategories() {
    var response = await CmsHelper.get({
      type: 'categories',
      sort: {
        category: 1
      }
    })
    var categories = []
    response.forEach(function(category, i) {
      categories.push({
        key: category._id,
        value: category._id,
        text: category.category
      })
    })
    this.setState({
      categories: categories
    })
  }

  handleChangeTab(tab) {
    this.setState({
      tab: tab
    })
  }

  loadTitle() {
    this.setState({
      title: this.props.settings.title
    })
  }

  handleChangeTitle(e) {
    this.setState({
      title: e.target.value
    })
  }

  handleSaveSettings() {
    this.props.onChangeSettings({
      title: this.state.title,
      categories: this.state.wbtCategories
    })
  }

  async loadRevisions() {
    var revisions = await CmsHelper.get({
      type: 'revisions',
      filter: {
        id: this.props.wbtId
      },
      sort: {
        _created: -1
      }
    })
    if(revisions === undefined) {
      revisions = []
    }
    var thisWindow = this
    this.setState({
      revisions: revisions
    }, function() {
      var revisions = this.state.revisions
      revisions.forEach(function(revision, index) {
        thisWindow.loadRevisionsName(revision.created_by, index)
      });
    })
  }

  async loadRevisionsName(id, index) {
    var response = await CmsHelper.get({
      type: 'users',
      id: id
    })
    if(response !== undefined) {
      var revisions = this.state.revisions
      revisions[index].name = response.first_name + ' ' + response.last_name
      this.setState({
        revisions: revisions
      })
    }
  }

  loadName(id) {
    return id
  }

  handleSelectRevision(index) {
    this.props.onSelectRevision(this.state.revisions[index])
  }

  handleChangeCategories(e, {value}) {
    this.setState({
      wbtCategories: value
    })
  }

  render() {
    var selectedActionIndex = -1
    var actions = []
    if(this.props.actions.length > 1) {
      this.props.actions.forEach((action, i) => {
        if(i > 0) {
          if(action.selected) {
            selectedActionIndex = i - 1
          }
          actions.push(action)
        }
      })
    }

    return(
      <div className="modal-window settings">
        <div className="main">
          <div className="tab-buttons">
            <div
              className={'tab-button' + (this.state.tab === 'settings' ? ' active' : '')}
              onClick={this.handleChangeTab.bind(this, 'settings')}
            >
              Kursinformationen
            </div>
            <div
              className={'tab-button' + (this.state.tab === 'history' ? ' active' : '')}
              onClick={this.handleChangeTab.bind(this, 'history')}
            >
              Versionen
            </div>
          </div>
          <div className="tabs">
            <div
              className={'tab' + (this.state.tab === 'settings' ? ' active' : '')}
            >
              <div className="headline">Kursinformationen</div>
              <div className="form">
                <div className="field">
                  <div className="category">WBT Vorschaubild</div>
                  <InputImage
                    {...this.props}
                    value={this.props.settings.image}
                  />
                </div>
                <div className="field">
                  <div className="category">WBT Titel</div>
                  <input
                    type="text"
                    value={this.state.title}
                    placeholder="WBT Titel"
                    onChange={this.handleChangeTitle.bind(this)}
                  />
                </div>
                <div className="field">
                  <div className="category">Kategorie</div>
                  <Form.Select
                    options={this.state.categories}
                    value={this.state.wbtCategories}
                    multiple
                    placeholder="Kategorien"
                    onChange={this.handleChangeCategories.bind(this)}
                  />
                </div>
                <button
                  className="button highlighted round"
                  onClick={this.handleSaveSettings.bind(this)}
                >
                  <span className="label">Speichern</span>
                </button>
              </div>
            </div>
            <div
              className={'tab' + (this.state.tab === 'history' ? ' active' : '')}
            >
              <div className="headline">Versionen</div>
              <div className="history-columns">
                <div className="column">
                  <ul>
                    {
                      this.state.revisions.map((revision, index) => {
                        return(
                          <li key={index}>
                            <button
                              onClick={this.handleSelectRevision.bind(this, index)}
                            >
                              {CommonHelper.formatDateTime(revision._created)} – <span className="name">{revision.name}</span>
                              <div className="hover">
                                <span className="icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10.108" viewBox="0 0 11 10.108">
                                    <path d="M18.946,16a5.057,5.057,0,0,0-5.017,4.459H13l1.189,1.486,1.189-1.486h-.85a4.452,4.452,0,1,1,.609,2.917l-.488.334A5.053,5.053,0,1,0,18.946,16Zm-.3,2.676v2.518l.111.093,1.719,1.375.372-.465-.232-.186-1.375-1.1V18.676" transform="translate(-13 -16)" />
                                  </svg>
                                </span>
                                <span className="label">Zurücksetzen</span>
                              </div>
                            </button>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="close-modal"
          onClick={this.props.onCloseModal}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25.223" height="25.223" viewBox="0 0 25.223 25.223">
            <path d="M0,10.165H7.671v7.671h2.4V10.165h7.765v-2.4H10.071V0h-2.4V7.765H0Z" transform="translate(12.611 0) rotate(45)" />
          </svg>
        </button>
      </div>
    )
  }
}

export default withRouter(Window)
