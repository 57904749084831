import React from 'react'

import EditorOverlayDragButtonSourceModule from './EditorOverlayDragButtonSourceModule'
import EditorOverlayDragButtonSourceElement from './EditorOverlayDragButtonSourceElement'
import EditorOverlayDragButtonSourceColumnElement from './EditorOverlayDragButtonSourceColumnElement'
import Tooltip from '../../../../tooltip/Tooltip'

import ConfigHelper from '../../../../helpers/Config'

class EditorOverlay extends React.Component {
  render() {
    var show = this.props.show
    var buttonDrag
    if(this.props.elementType !== undefined) {
      var elementConfig = ConfigHelper.getElementConfig(this.props.elementType)
      if(elementConfig.type === this.props.elementType) {
        if(elementConfig.itemType === 'column-element') {
          buttonDrag = (
            <EditorOverlayDragButtonSourceColumnElement
              elementType={this.props.elementType}
              elementIcon={elementConfig.icon}
              elementLabel={elementConfig.title}
              moduleId={this.props.moduleId}
              elementId={this.props.elementId}
              columnId={this.props.columnId}
              columnElementId={this.props.columnElementId}
              onDragStart={this.props.onDragStart}
              onDragEnd={this.props.onDragEnd}
            />
          )
        } else if(elementConfig.itemType === 'element') {
          buttonDrag = (
            <EditorOverlayDragButtonSourceElement
              elementType={this.props.elementType}
              elementIcon={elementConfig.icon}
              elementLabel={elementConfig.title}
              moduleId={this.props.moduleId}
              elementId={this.props.elementId}
              columnId={this.props.columnId}
              columnElementId={this.props.columnElementId}
              onDragStart={this.props.onDragStart}
              onDragEnd={this.props.onDragEnd}
              test="teattaetaet"
            />
          )
        }
      }
    } else {
      buttonDrag = (
        <EditorOverlayDragButtonSourceModule
          moduleId={this.props.moduleId}
          onDragStart={this.props.onDragStart}
          onDragEnd={this.props.onDragEnd}
        />
      )
    }
    return(
      <div
        className={'editor-overlay editor-overlay-' + this.props.type + (show ? ' show' : '')}
      >
        <div className="drag-button-bar">
          {buttonDrag}
        </div>
        {
          this.props.tooltipMode === 2 ?
            <Tooltip
              tooltip="edit_element"
            />
          :
            ''
        }
        <div className="editor-overlay-bar-button-group">
          <button className="editor-overlay-bar-button button-edit highlighted" onClick={this.props.onEdit}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12.001" viewBox="0 0 12 12.001">
              <path d="M10.936,2.453a1.15,1.15,0,0,0-.819.34L2.606,10.3a.46.46,0,0,0-.129.25L1.9,13.912a.465.465,0,0,0,.535.536L5.8,13.875a.469.469,0,0,0,.25-.129l7.511-7.511a1.161,1.161,0,0,0,0-1.639l-1.8-1.8a1.155,1.155,0,0,0-.82-.339ZM5.856,12.625l-2.13-2.13L9.3,4.923l2.13,2.13ZM3.28,11.359l1.712,1.712-2.064.352ZM12.9,5.579l-.819.819-2.13-2.13.819-.82a.231.231,0,0,1,.327,0l1.8,1.8a.232.232,0,0,1,0,.328Z" transform="translate(-1.897 -2.453)" />
            </svg>
          </button>
          <button className="editor-overlay-bar-button button-duplicate" onClick={this.props.onDuplicate}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <path d="M14.152,5H8.75A.749.749,0,0,0,8,5.751V8H5.75A.749.749,0,0,0,5,8.751v7.5A.751.751,0,0,0,5.75,17h7.5A.751.751,0,0,0,14,16.251V14H16.25A.751.751,0,0,0,17,13.251V7.84ZM14,5.909,16.1,8H14ZM13.25,16.251H5.75v-7.5h4.5V11a.751.751,0,0,0,.749.751H13.25ZM11,11V8.909L13.1,11Zm5.251,2.251H14V10.84L11.152,8h-2.4V5.751h4.5V8A.751.751,0,0,0,14,8.751H16.25Z" transform="translate(-5 -5)" />
            </svg>
          </button>
          <button className="editor-overlay-bar-button button-delete" onClick={this.props.onDelete}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13.286" viewBox="0 0 12 13.286">
              <g transform="translate(-4.859 0)">
                <path d="M30.324,45.854a.461.461,0,0,0,.461-.454V41.7a.461.461,0,0,0-.922,0v3.7A.461.461,0,0,0,30.324,45.854Z" transform="translate(-21.68 -35.757)" />
                <path d="M47,45.854a.46.46,0,0,0,.461-.454V41.7a.461.461,0,0,0-.921,0v3.7A.461.461,0,0,0,47,45.854Z" transform="translate(-36.136 -35.757)" />
                <path d="M63.664,45.854a.461.461,0,0,0,.461-.454V41.7a.461.461,0,0,0-.922,0v3.7A.461.461,0,0,0,63.664,45.854Z" transform="translate(-50.589 -35.757)" />
                <path d="M16.4,2.23H13.45L13.071.96A1.354,1.354,0,0,0,11.785,0H9.936A1.354,1.354,0,0,0,8.65.96L8.271,2.23H5.32a.461.461,0,1,0,0,.922h.548v8.869a1.273,1.273,0,0,0,1.271,1.264h7.455a1.274,1.274,0,0,0,1.271-1.271V3.137H16.4a.461.461,0,0,0,.461-.461A.449.449,0,0,0,16.4,2.23ZM9.541,1.223h0a.428.428,0,0,1,.394-.3H11.8a.411.411,0,0,1,.4.3l.3,1.005H9.222Zm5.4,10.892h-.032a.357.357,0,0,1-.33.24H7.139A.357.357,0,0,1,6.788,12V3.137h8.155Z" />
              </g>
            </svg>
          </button>
          {
            this.props.type === 'module' ?
              <button className="editor-overlay-bar-button button-template" onClick={this.props.onSaveAsTemplate}>
                <svg style={{'width': '13px'}} xmlns="http://www.w3.org/2000/svg" width="19.031" height="19.031" viewBox="0 0 19.031 19.031">
                  <g>
                    <path d="M17.5.12h-16A1.43,1.43,0,0,0,.12,1.53v16a1.42,1.42,0,0,0,1.41,1.41h16a1.41,1.41,0,0,0,1.41-1.41v-16A1.42,1.42,0,0,0,17.5.12ZM18,1.53v6.1H8.58V1.06H17.5a.47.47,0,0,1,.47.47Zm-16.9,16v-16a.47.47,0,0,1,.47-.47h6.1V18H1.54a.47.47,0,0,1-.48-.46h0ZM17.5,18H8.58V8.58H18V17.5a.47.47,0,0,1-.47.47Z" />
                    <path d="M17.5,19h-16A1.54,1.54,0,0,1,0,17.5v-16A1.54,1.54,0,0,1,1.53,0h16A1.54,1.54,0,0,1,19,1.53v16A1.54,1.54,0,0,1,17.5,19ZM1.53.25A1.29,1.29,0,0,0,.25,1.53v16a1.28,1.28,0,0,0,1.28,1.28h16a1.28,1.28,0,0,0,1.28-1.28v-16A1.28,1.28,0,0,0,17.5.25Zm16,17.84h-9V8.45h9.64V17.5a.59.59,0,0,1-.59.59Zm-8.8-.25h8.8a.34.34,0,0,0,.34-.34V8.7H8.7Zm-7.17.25a.59.59,0,0,1-.59-.59v-.12h0V1.53a.6.6,0,0,1,.6-.59H7.76V18.09H1.53Zm-.34-.54a.35.35,0,0,0,.34.29h6V1.19h-6a.35.35,0,0,0-.35.34Zm16.9-9.79H8.45V.94H17.5a.6.6,0,0,1,.58.47h0V7.76ZM8.7,7.51h9.14v-6a.34.34,0,0,0-.34-.34H8.7Z" />
                  </g>
                </svg>
              </button>
            :
              ''
          }
        </div>
        <div className="editor-overlay-background"></div>
      </div>
    )
  }
}

export default EditorOverlay
