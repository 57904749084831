import React from 'react'

import PanelElements from './panels/elements/Elements'
import PanelEdit from './panels/edit/Edit'

class Panel extends React.Component {
  getElement() {
    var element
    if(this.props.wbt.modules !== undefined) {
      this.props.wbt.modules.map((module, moduleIndex) => {
        if(module.id === this.props.moduleId) {
          if(module.elements !== undefined) {
            module.elements.map((moduleElement, moduleElementIndex) => {
              if(moduleElement.id === this.props.elementId) {
                element = moduleElement
              }
              return null
            })
          }
        }
        return null
      })
    }
    return element
  }

  render() {
    return(
      <div id="editor-panel" className={'view-' + this.props.view + (this.props.previewMode ? ' preview-mode' : '') + (this.props.overlayId !== '' ? ' overlay-mode' : '')}>
        <PanelElements />
        <PanelEdit
          {...this.props}
          moduleId={this.props.moduleId}
          elementId={this.props.elementId}
        />
      </div>
    )
  }
}

export default Panel
