import React from 'react'
import ReactDOM from 'react-dom'
import { DropTarget } from 'react-dnd'
import {Editor, EditorState, ContentState} from 'draft-js'

import ModuleTextarea from './ModuleTextarea'
import Elements from './Elements'
import EditorDropIndicator from '../components/EditorDropIndicator'
import EditorOverlay from '../components/EditorOverlay'

const target = {
  canDrop(props, monitor) {
    if(monitor.getItem().itemType === 'module') {
      return true
    } else {
      return false
    }
  },
  hover(props, monitor, component) {
    var componentRect = ReactDOM.findDOMNode(component).getBoundingClientRect()
    var y = monitor.getClientOffset().y - componentRect.y
    if(y < componentRect.height / 2) {
      if(component.state.isDragOver !== 'top') {
        component.setState({
          isDragOver: 'top'
        })
      }
    } else {
      if(component.state.isDragOver !== 'bottom') {
        component.setState({
          isDragOver: 'bottom'
        })
      }
    }
  },
  drop(props, monitor, component) {
    if(monitor.canDrop()) {
      var item = monitor.getItem()
      var moduleIndex = props.moduleIndex
      if(component.state.isDragOver === 'bottom') {
        moduleIndex += 1
      }
      var source = {
        moduleId: item.moduleId
      }
      var target = {
        moduleIndex: moduleIndex
      }
      props.onMove(source, target)
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver({
      shallow: true
    })
  }
}

class Module extends React.Component {
  constructor(props) {
    super(props)

    const {
      headline = ''
    } = this.props.module.settings

    this.state = {
      isDragOver: false,
      isMouseOver: false,
      isDragging: false,
      testStateContent: EditorState.createWithContent(ContentState.createFromText(headline)),
      testState: headline
    }
  }

  handleMouseOver() {
    this.setState({
      isMouseOver: true
    })
  }

  handleMouseLeave() {
    this.setState({
      isMouseOver: false
    })
  }

  handleDragStart() {
    this.setState({
      isDragging: true
    })
  }

  handleDragEnd() {
    this.setState({
      isDragging: false
    })
  }

  handleDuplicate() {
    this.props.onDuplicate({
      moduleId: this.props.module.id
    })
  }

  handleDelete() {
    this.props.onDelete({
      moduleId: this.props.module.id
    })
  }

  handleEdit() {
    this.props.onEdit({
      moduleId: this.props.module.id
    })
  }

  changeHeadline(textContent) {
    var text = textContent.getCurrentContent().getPlainText('\u0001')
    this.setState({
      testStateContent: textContent,
      testState: text
    })
    this.props.onChangeModuleHeadline({
      moduleId: this.props.module.id,
      headline: text
    })
  }

  handleSaveAsTemplate() {
    this.props.onSaveAsTemplate({
      moduleId: this.props.module.id
    })
  }


  render() {
    var introslider = false
    if(this.props.module.elements.length === 1 && this.props.module.elements[0].type === 'introslider') {
      introslider = true
    }
    return this.props.connectDropTarget(
      <div
        className={'module' + (this.state.isDragging ? ' is-dragging' : '') + (this.props.module.settings.dark_background ? ' dark-background' : '') +  (introslider ? ' full-width' : '')}
        onMouseOver={this.handleMouseOver.bind(this)}
        onMouseLeave={this.handleMouseLeave.bind(this)}
      >
        <EditorDropIndicator
          type="module"
          position="before"
          show={this.props.isOver && this.state.isDragOver === 'top' && this.state.isDragOver === 'top' ? true : false}
        />
        <div className="module-container">
          {
            this.props.module.settings.headline_show && !introslider ?
              <>
                <h1>
                  <ModuleTextarea
                    {...this.props}
                    fieldName="headline"
                    text={this.props.module.settings.headline}
                    placeholder="Überschrift..."
                  />
                </h1>
                <hr />
              </>
            :
              <div className="headline-placeholder" style={{'width': '100%', 'height': '25px'}}></div>
          }
          <Elements
            {...this.props}
            elements={this.props.module.elements}
          />
          {
            !introslider && (this.props.type === undefined || this.props.type !== 'overlay') ?
              <button className="button">Weiter</button>
            :
              ''
          }
        </div>
        <EditorDropIndicator
          type="module"
          position="after"
          show={this.props.isOver && this.state.isDragOver === 'bottom' ? true : false}
        />
        {
          this.props.type !== 'module_template' && (this.props.type === undefined || this.props.type !== 'overlay') ?
            <EditorOverlay
              type="module"
              moduleId={this.props.module.id}
              show={this.state.isMouseOver || (this.props.panelView === 'edit' && this.props.module.id === this.props.panelModuleId)}
              onDragStart={this.handleDragStart.bind(this)}
              onDragEnd={this.handleDragEnd.bind(this)}
              onDuplicate={this.handleDuplicate.bind(this)}
              onDelete={this.handleDelete.bind(this)}
              onEdit={this.handleEdit.bind(this)}
              onSaveAsTemplate={this.handleSaveAsTemplate.bind(this)}
            />
          :
            ''
        }
      </div>
    )
  }
}

export default DropTarget(
  ['module'],
  target,
  collect
)(Module)
