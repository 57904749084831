import React from 'react'
import {Link} from 'react-router-dom';
import Select from 'react-select';

import CommonHelper from '../../../../helpers/Common'
import CmsHelper from '../../../../helpers/Cms'

const selectStyles = {
  option: (provided, state) => ({
    ...provided
  }),
  control: () => ({
    display: 'flex'
  }),
  singleValue: (provided, state) => {
    return {
      ...provided
    }
  }
}

class ListItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      image: ''
    }
  }

  componentDidMount() {
    this.loadImage()
  }

  async loadImage() {
    var user = await CmsHelper.get({
      type: 'users',
      id: this.props.item.created_by
    })
    if(user !== undefined) {
      var profileImage = CmsHelper.getImageUrl({
        file: user.image.path,
        width: 62,
        height: 62
      })
      this.setState({
        image: profileImage
      })
    }
  }

  toggleMoreOptions(id, e) {
    e.preventDefault()

    this.props.toggleMoreOptions(id)
  }

  handleDuplicate(id, e) {
    e.preventDefault()

    this.props.handleDuplicate(id)
  }

  handleDelete(id, e) {
    e.preventDefault()

    this.props.handleDelete(id)
  }

  handleExternalLink(id, e) {
    e.preventDefault()

    this.props.onOpenExternalLink(id)
  }

  render() {
    var date = CommonHelper.formatDate(this.props.item._created)
    var modulesCount = this.props.item.content.modules.length
    var modules
    if(modulesCount === 0 || modulesCount > 1) {
      modules = modulesCount + ' Module'
    } else {
      modules = modulesCount + ' Modul'
    }
    return(
      <Link
        key={this.props.item._id}
        to={'/editor/' + this.props.item._id}
        className="teaser button"
      >
        <div
          className="image"
        >
          <ListItemImage
            id={this.props.item.image}
          />
          <div
            className={'button highlighted more-options-toggle' + (this.props.item._id === this.props.moreOptionsId ? ' active' : '')}
            onClick={this.toggleMoreOptions.bind(this, this.props.item._id)}
          >
            <span className="icon open">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="4" viewBox="0 0 20 4">
                <g transform="translate(-423.005 -378)">
                  <circle cx="2" cy="2" r="2" transform="translate(423.005 378)" />
                  <circle cx="2" cy="2" r="2" transform="translate(431.005 378)" />
                  <circle cx="2" cy="2" r="2" transform="translate(439.005 378)" />
                </g>
              </svg>
            </span>
            <span className="icon close">
              <svg xmlns="http://www.w3.org/2000/svg" width="12.864" height="12.864" viewBox="0 0 12.864 12.864">
                <path d="M0,5.184H3.912V9.1H5.136V5.184H9.1V3.96H5.136V0H3.912V3.96H0Z" transform="translate(6.432) rotate(45)" />
              </svg>
            </span>
          </div>
          {
            this.props.item._id === this.props.moreOptionsId ?
              <div className="more-options">
                <button className="option">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.8" height="13.801" viewBox="0 0 13.8 13.801">
                      <path d="M12.292,2.453a1.323,1.323,0,0,0-.942.391L2.713,11.481a.529.529,0,0,0-.148.287L1.9,15.631a.534.534,0,0,0,.615.616l3.863-.659a.539.539,0,0,0,.287-.148L15.307,6.8a1.335,1.335,0,0,0,0-1.885L13.234,2.843a1.328,1.328,0,0,0-.943-.39ZM6.45,14.15,4,11.7l6.407-6.407,2.45,2.45ZM3.487,12.695l1.968,1.968-2.374.405ZM14.553,6.048l-.942.942-2.45-2.45L12.1,3.6a.266.266,0,0,1,.376,0l2.073,2.073a.267.267,0,0,1,0,.378Z" transform="translate(-1.897 -2.453)" />
                    </svg>
                  </div>
                  <div className="label">Bearbeiten</div>
                </button>
                <button
                  className="option"
                  onClick={this.handleDuplicate.bind(this, this.props.item._id)}
                >
                  <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.8" height="13.8" viewBox="0 0 13.8 13.8">
                    <path d="M15.525,5H9.313a.861.861,0,0,0-.861.863V8.45H5.863A.861.861,0,0,0,5,9.313v8.625a.863.863,0,0,0,.863.862h8.625a.864.864,0,0,0,.864-.862V15.35h2.586a.863.863,0,0,0,.862-.862V8.266ZM15.35,6.046l2.41,2.4H15.35Zm-.862,11.892H5.863V9.313h5.175V11.9a.863.863,0,0,0,.862.863h2.588ZM11.9,11.9V9.5l2.41,2.4Zm6.038,2.588H15.35V11.716L12.075,8.45H9.315V5.863h5.173V8.45a.863.863,0,0,0,.864.863h2.586Z" transform="translate(-5 -5)" />
                  </svg>
                  </div>
                  <div className="label">Duplizieren</div>
                </button>
                <button
                  className="option"
                  onClick={this.handleDelete.bind(this, this.props.item._id)}
                >
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.8" height="15.278" viewBox="0 0 13.8 15.278">
                      <path d="M30.393,46.546a.53.53,0,0,0,.53-.522V41.768a.53.53,0,0,0-1.06,0v4.256A.53.53,0,0,0,30.393,46.546Z" transform="translate(-26.041 -34.935)" />
                      <path d="M47.065,46.546a.53.53,0,0,0,.53-.522V41.768a.53.53,0,1,0-1.059,0v4.256A.53.53,0,0,0,47.065,46.546Z" transform="translate(-40.165 -34.935)" />
                      <path d="M63.733,46.546a.53.53,0,0,0,.53-.522V41.768a.53.53,0,0,0-1.06,0v4.256A.53.53,0,0,0,63.733,46.546Z" transform="translate(-54.285 -34.935)" />
                      <path d="M18.129,2.565h-3.39L14.3,1.1A1.557,1.557,0,0,0,12.824,0H10.7A1.557,1.557,0,0,0,9.219,1.1L8.783,2.565H5.39a.53.53,0,1,0,0,1.06H6.02v10.2a1.464,1.464,0,0,0,1.461,1.454h8.573a1.465,1.465,0,0,0,1.462-1.462V3.607h.613a.53.53,0,0,0,.53-.53.516.516,0,0,0-.53-.513ZM10.243,1.406h0A.492.492,0,0,1,10.7,1.06h2.142a.472.472,0,0,1,.46.35l.34,1.156H9.877Zm6.213,12.526h-.037a.41.41,0,0,1-.379.276H7.481a.41.41,0,0,1-.4-.409V3.607h9.378Z" transform="translate(-4.859 0)" />
                    </svg>
                  </div>
                  <div className="label">Löschen</div>
                </button>
                <button
                  className="option"
                  onClick={this.handleExternalLink.bind(this, this.props.item._id)}
                >
                  <div className="label">Externen Link erstellen</div>
                </button>
              </div>
            :
              null
          }
        </div>
        <div className="main">
          <div className="main-top">
            <div className="date">{date}</div>
            <div className="modules">{modules}</div>
          </div>
          <div className="headline">{this.props.item.title}</div>
          <div className="authors">
            <div
              className="author"
              style={{backgroundImage: 'url(' + this.state.image + ')'}}
            ></div>
          </div>
        </div>
        <div className="button highlighted">
          <div className="label">Bearbeiten</div>
        </div>
      </Link>
    )
  }
}

class ListItemImage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      src: ''
    }
  }

  componentDidMount() {
    this.loadSrc()
  }

  async loadSrc() {
    var file
    if(this.props.id !== undefined && this.props.id !== '') {
      var response = await CmsHelper.get({
        type: 'attachments',
        id: this.props.id
      })
      file = response.file.path
    } else {
      var response = await CmsHelper.getSingleton({
        name: 'defaults'
      })
      file = response.placeholderImage.path
    }
    var image = CmsHelper.getImageUrl({
      file: file,
      width: 800,
      height: 450
    })
    this.setState({
      src: image
    })
  }

  render() {
    return(
      <div
        className="img"
        style={{backgroundImage: 'url(' + this.state.src + ')'}}
      >
      </div>
    )
  }
}

class ListTableItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      image: '',
      name: ''
    }
  }

  componentDidMount() {
    this.loadImage()
  }

  async loadImage() {
    var user = await CmsHelper.get({
      type: 'users',
      id: this.props.item.created_by
    })
    if(user !== undefined) {
      var name = user.first_name + ' ' + user.last_name
      this.setState({
        name: name.trim()
      })
    }
  }

  toggleMoreOptions(id, e) {
    e.preventDefault()

    this.props.toggleMoreOptions(id)
  }

  handleDuplicate(id, e) {
    e.preventDefault()

    this.props.handleDuplicate(id)
  }

  handleDelete(id, e) {
    e.preventDefault()

    this.props.handleDelete(id)
  }

  render() {
    var date = CommonHelper.formatDate(this.props.item._created)
    var modulesCount = this.props.item.content.modules.length
    var modules
    if(modulesCount === 0 || modulesCount > 1) {
      modules = modulesCount + ' Module'
    } else {
      modules = modulesCount + ' Modul'
    }
    return(
      <div
        className="tr"
      >
        <div className="td">
          <Link
            to={'/editor/' + this.props.item._id}
          >
            {this.props.item.title}
          </Link>
        </div>
        <div className="td">{CommonHelper.formatDate(this.props.item._modified)}</div>
        <div className="td">{this.props.item.content.modules.length}</div>
        <div className="td">{this.state.name}</div>
        <div
          className={'button highlighted more-options-toggle' + (this.props.item._id === this.props.moreOptionsId ? ' active' : '')}
          onClick={this.toggleMoreOptions.bind(this, this.props.item._id)}
        >
          <span className="icon open">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="4" viewBox="0 0 20 4">
              <g transform="translate(-423.005 -378)">
                <circle cx="2" cy="2" r="2" transform="translate(423.005 378)" />
                <circle cx="2" cy="2" r="2" transform="translate(431.005 378)" />
                <circle cx="2" cy="2" r="2" transform="translate(439.005 378)" />
              </g>
            </svg>
          </span>
          <span className="icon close">
            <svg xmlns="http://www.w3.org/2000/svg" width="12.864" height="12.864" viewBox="0 0 12.864 12.864">
              <path d="M0,5.184H3.912V9.1H5.136V5.184H9.1V3.96H5.136V0H3.912V3.96H0Z" transform="translate(6.432) rotate(45)" />
            </svg>
          </span>
        </div>
        {
          this.props.item._id === this.props.moreOptionsId ?
            <div className="more-options">
              <button className="option">
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.8" height="13.801" viewBox="0 0 13.8 13.801">
                    <path d="M12.292,2.453a1.323,1.323,0,0,0-.942.391L2.713,11.481a.529.529,0,0,0-.148.287L1.9,15.631a.534.534,0,0,0,.615.616l3.863-.659a.539.539,0,0,0,.287-.148L15.307,6.8a1.335,1.335,0,0,0,0-1.885L13.234,2.843a1.328,1.328,0,0,0-.943-.39ZM6.45,14.15,4,11.7l6.407-6.407,2.45,2.45ZM3.487,12.695l1.968,1.968-2.374.405ZM14.553,6.048l-.942.942-2.45-2.45L12.1,3.6a.266.266,0,0,1,.376,0l2.073,2.073a.267.267,0,0,1,0,.378Z" transform="translate(-1.897 -2.453)" />
                  </svg>
                </div>
                <div className="label">Bearbeiten</div>
              </button>
              <button
                className="option"
                onClick={this.handleDuplicate.bind(this, this.props.item._id)}
              >
                <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.8" height="13.8" viewBox="0 0 13.8 13.8">
                  <path d="M15.525,5H9.313a.861.861,0,0,0-.861.863V8.45H5.863A.861.861,0,0,0,5,9.313v8.625a.863.863,0,0,0,.863.862h8.625a.864.864,0,0,0,.864-.862V15.35h2.586a.863.863,0,0,0,.862-.862V8.266ZM15.35,6.046l2.41,2.4H15.35Zm-.862,11.892H5.863V9.313h5.175V11.9a.863.863,0,0,0,.862.863h2.588ZM11.9,11.9V9.5l2.41,2.4Zm6.038,2.588H15.35V11.716L12.075,8.45H9.315V5.863h5.173V8.45a.863.863,0,0,0,.864.863h2.586Z" transform="translate(-5 -5)" />
                </svg>
                </div>
                <div className="label">Duplizieren</div>
              </button>
              <button
                className="option"
                onClick={this.handleDelete.bind(this, this.props.item._id)}
              >
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.8" height="15.278" viewBox="0 0 13.8 15.278">
                    <path d="M30.393,46.546a.53.53,0,0,0,.53-.522V41.768a.53.53,0,0,0-1.06,0v4.256A.53.53,0,0,0,30.393,46.546Z" transform="translate(-26.041 -34.935)" />
                    <path d="M47.065,46.546a.53.53,0,0,0,.53-.522V41.768a.53.53,0,1,0-1.059,0v4.256A.53.53,0,0,0,47.065,46.546Z" transform="translate(-40.165 -34.935)" />
                    <path d="M63.733,46.546a.53.53,0,0,0,.53-.522V41.768a.53.53,0,0,0-1.06,0v4.256A.53.53,0,0,0,63.733,46.546Z" transform="translate(-54.285 -34.935)" />
                    <path d="M18.129,2.565h-3.39L14.3,1.1A1.557,1.557,0,0,0,12.824,0H10.7A1.557,1.557,0,0,0,9.219,1.1L8.783,2.565H5.39a.53.53,0,1,0,0,1.06H6.02v10.2a1.464,1.464,0,0,0,1.461,1.454h8.573a1.465,1.465,0,0,0,1.462-1.462V3.607h.613a.53.53,0,0,0,.53-.53.516.516,0,0,0-.53-.513ZM10.243,1.406h0A.492.492,0,0,1,10.7,1.06h2.142a.472.472,0,0,1,.46.35l.34,1.156H9.877Zm6.213,12.526h-.037a.41.41,0,0,1-.379.276H7.481a.41.41,0,0,1-.4-.409V3.607h9.378Z" transform="translate(-4.859 0)" />
                  </svg>
                </div>
                <div className="label">Löschen</div>
              </button>
            </div>
          :
            null
        }
      </div>
    )
  }
}

class List extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      items: [],
      moreOptionsId: '',
      filtersCategory: 'all',
      filterSearch: '',
      view: 'teasers',
      order: {
        value: 'last-edited',
        label: 'Zuletzt bearbeitet'
      }
    }
  }

  componentDidMount() {
    this.getItems()
  }

  async getItems(id) {
    var response = await CmsHelper.get({
      type: 'wbts',
      filter: {
        type: 'wbt'
      },
      sort: {
        _modified: -1
      }
    })
    this.setState({
      items: response
    })
    var i = 0
    var thisDashboard = this
    this.setState({
      items: response
    })
  }

  toggleMoreOptions(id) {
    if(this.state.moreOptionsId === id) {
      this.setState({
        moreOptionsId: ''
      })
    } else {
      this.setState({
        moreOptionsId: id
      })
    }
  }

  handleDuplicate(id) {
    this.props.onDuplicateWBT(id)
  }

  handleDelete(id) {
    this.props.onDeleteWBT(id)
  }

  handleOpenExternalLink(id) {
    this.props.onOpenModal('external_link_' + id)
  }

  handleOpenWbtModal() {
    this.props.onOpenModal('add_wbt')
  }

  handleFilterCategory(category) {
    this.setState({
      filtersCategory: category
    })
  }

  handleView(view) {
    this.setState({
      view: view
    })
  }

  handleChangeOrder(option) {
    this.setState({
      order: option
    })
  }

  handleChangeSearch(e) {
    this.setState({
      filterSearch: e.target.value
    })
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }

  render() {
    const {
      filtersCategory,
      filterSearch,
      order,
      view
    } = this.state

    var items = this.state.items

    var countMyWBTs = 0
    var itemsIndex = 0
    items.forEach(function() {
      if(items[itemsIndex].created_by == JSON.parse(sessionStorage.getItem('userId'))) {
        countMyWBTs++
      }
      itemsIndex++
    })

    var newItems = []
    var newItemsIndex = 0
    items.forEach(function() {
      var push = true
      if(!(filtersCategory === 'all' || (filtersCategory === 'my' && items[newItemsIndex].created_by == JSON.parse(sessionStorage.getItem('userId'))))) {
        push = false
      }
      if(items[newItemsIndex].title.toLowerCase().indexOf(filterSearch.toLowerCase()) === -1) {
        push = false
      }
      if(push) {
        var newItem = items[newItemsIndex]
        newItems.push(items[newItemsIndex])
      }
      newItemsIndex++
    })

    var orderValue = order.value
    if(orderValue == 'alphabetical') {
      newItems.sort(this.dynamicSort('title'));
    } else if('last-created') {
      newItems.sort(this.dynamicSort('-_created'));
    } else if('last-edited') {
      newItems.sort(this.dynamicSort('-_modified'));
    }

    return(
      <div className="list list-wbts">
        <div className="main-button">
          <button
            className="button plus highlighted round"
            onClick={this.handleOpenWbtModal.bind(this)}
          >
            <span className="icon">+</span>
            <span className="label">Neues WBT erstellen</span>
          </button>
        </div>
        <div className="filters">
          <div className="categories-filters">
            <button
              className={'filter-button' + (this.state.filtersCategory == 'all' ? ' active' : '')}
              onClick={this.handleFilterCategory.bind(this, 'all')}
            >
              <span className="label">Alle WBTs</span>
              <span className="count">{this.state.items.length}</span>
            </button>
            <button
              className={'filter-button' + (this.state.filtersCategory == 'my' ? ' active' : '')}
              onClick={this.handleFilterCategory.bind(this, 'my')}
            >
              <span className="label">Meine WBTs</span>
              <span className="count">{countMyWBTs}</span>
            </button>
          </div>
          <div className="main-filters">
            <div className="input input-select search">
              <input
                type="search"
                placeholder="Suchen"
                onChange={this.handleChangeSearch.bind(this)}
              />
              <button>
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10.962" height="10.976" viewBox="0 0 10.962 10.976">
                    <path d="M13.339,12.807a4.04,4.04,0,1,0-.533.533l3.034,3.034a.367.367,0,0,0,.518-.518ZM6.93,10.236A3.306,3.306,0,1,1,7.9,12.573a3.305,3.305,0,0,1-.968-2.337Z" transform="translate(-5.844 -5.844)" strokeWidth="0.65" />
                  </svg>
                </div>
              </button>
            </div>
            <div className="input input-select simple">
              <Select
                options={
                  [
                    {
                      value: 'last-edited',
                      label: 'Zuletzt bearbeitet'
                    },
                    {
                      value: 'last-created',
                      label: 'Zuletzt erstellt'
                    },
                    {
                      value: 'alphabetical',
                      label: 'Alphabetisch'
                    }
                  ]
                }
                value={this.state.order}
                styles={selectStyles}
                onChange={this.handleChangeOrder.bind(this)}
              />
            </div>
            <div className="view">
              <span className="label">Darstellung</span>
              <div className="view-buttons">
                <button
                  className={this.state.view === 'teasers' ? 'active' : ''}
                  onClick={this.handleView.bind(this, 'teasers')}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="17" viewBox="0 0 11 17">
                    <g id="Gruppe_752" data-name="Gruppe 752" transform="translate(-636 -220)">
                      <g id="Rechteck_77" data-name="Rechteck 77" transform="translate(636 220)" fill="none" stroke="#142847" strokeWidth="1">
                        <rect width="11" height="17" stroke="none"/>
                        <rect x="0.5" y="0.5" width="10" height="16" fill="none"/>
                      </g>
                      <line id="Linie_143" data-name="Linie 143" x2="10" transform="translate(636.5 225.5)" fill="none" stroke="#142847" strokeWidth="1"/>
                      <line id="Linie_144" data-name="Linie 144" x2="6" transform="translate(638.5 230.5)" fill="none" stroke="#142847" strokeWidth="1"/>
                      <line id="Linie_147" data-name="Linie 147" x2="6" transform="translate(638.5 228.5)" fill="none" stroke="#142847" strokeWidth="1"/>
                      <line id="Linie_145" data-name="Linie 145" x2="6" transform="translate(638.5 232.5)" fill="none" stroke="#142847" strokeWidth="1"/>
                    </g>
                  </svg>
                </button>
                <button
                  className={this.state.view === 'table' ? 'active' : ''}
                  onClick={this.handleView.bind(this, 'table')}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="17" viewBox="0 0 20.5 17">
                    <g id="Gruppe_751" data-name="Gruppe 751" transform="translate(-635 -215)">
                      <g id="Rechteck_78" data-name="Rechteck 78" transform="translate(635 215)" fill="none" stroke="#142847" strokeWidth="1">
                        <rect width="5" height="5" stroke="none"/>
                        <rect x="0.5" y="0.5" width="4" height="4" fill="none"/>
                      </g>
                      <g id="Rechteck_79" data-name="Rechteck 79" transform="translate(635 221)" fill="none" stroke="#142847" strokeWidth="1">
                        <rect width="5" height="5" stroke="none"/>
                        <rect x="0.5" y="0.5" width="4" height="4" fill="none"/>
                      </g>
                      <g id="Rechteck_80" data-name="Rechteck 80" transform="translate(635 227)" fill="none" stroke="#142847" strokeWidth="1">
                        <rect width="5" height="5" stroke="none"/>
                        <rect x="0.5" y="0.5" width="4" height="4" fill="none"/>
                      </g>
                      <line id="Linie_148" data-name="Linie 148" x2="16" transform="translate(639.5 217.5)" fill="none" stroke="#142847" strokeWidth="1"/>
                      <line id="Linie_149" data-name="Linie 149" x2="16" transform="translate(639.5 223.5)" fill="none" stroke="#142847" strokeWidth="1"/>
                      <line id="Linie_150" data-name="Linie 150" x2="16" transform="translate(639.5 229.5)" fill="none" stroke="#142847" strokeWidth="1"/>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {
          view === 'teasers' ?
            <div className="teasers">
              {
                newItems.map((item, index) => {
                  return (
                    <ListItem
                      key={item._id}
                      item={item}
                      toggleMoreOptions={this.toggleMoreOptions.bind(this, item._id)}
                      handleDuplicate={this.handleDuplicate.bind(this, item._id)}
                      handleDelete={this.handleDelete.bind(this, item._id)}
                      moreOptionsId={this.state.moreOptionsId}
                      onOpenExternalLink={this.handleOpenExternalLink.bind(this, item._id)}
                    />
                  )
                })
              }
            </div>
          :
            <div className="table wbts-table">
              <div className="thead">
                <div className="tr">
                  <div className="td">Kurstitel</div>
                  <div className="td">Zuletzt geändert</div>
                  <div className="td">Module</div>
                  <div className="td">Autor</div>
                </div>
              </div>
              <div className="tbody">
                {
                  newItems.map((item, index) => {
                    return (
                      <ListTableItem
                        key={index}
                        item={item}
                        toggleMoreOptions={this.toggleMoreOptions.bind(this, item._id)}
                        handleDuplicate={this.handleDuplicate.bind(this, item._id)}
                        handleDelete={this.handleDelete.bind(this, item._id)}
                        moreOptionsId={this.state.moreOptionsId}
                      />
                    )
                  })
                }
              </div>
            </div>
        }
      </div>
    )
  }
}

export default List
