import React from 'react'

import WindowAttachment from './windows/WindowAttachment'
import WindowSettings from './windows/WindowSettings'
import WindowAddTemplate from './windows/WindowAddTemplate'
import WindowAddModuleTemplate from './windows/WindowAddModuleTemplate'

class EditoModal extends React.Component {
  render() {
    if(this.props.modalShow) {
      switch(this.props.modalArgs.modal) {
        case 'attachment':
          return(
            <div id="modal">
              <WindowAttachment
                {...this.props}
              />
              <div className="modal-background"></div>
            </div>
          )
          break
        case 'settings':
          return(
            <div id="modal">
              <WindowSettings
                {...this.props}
              />
              <div className="modal-background"></div>
            </div>
          )
          break
        case 'add_template':
          return(
            <div id="modal">
              <WindowAddTemplate
                {...this.props}
              />
              <div className="modal-background"></div>
            </div>
          )
          break
        case 'add_module_template':
          return(
            <div id="modal">
              <WindowAddModuleTemplate
                {...this.props}
              />
              <div className="modal-background"></div>
            </div>
          )
          break
        default:
          return null
          break
      }
    } else {
      return null
    }
  }
}

export default EditoModal
